<template>
  <div>
    <div class="a">我们在一起...</div>
    <div class="b">已经200多天啦~</div>
    <br/><br/><br/>
    <div class="c">跟婷婷在一起的每一天</div>
    <div class="c">都很快乐呢(*^▽^*)</div>
    <br/>
    <div class="c">跟我一起看看过去的点点滴滴吧~</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.a,
.b,
.c {
  text-align: center;
  color: white;
}
.a {
  font-size: 9vw;
  padding-top: 12vh;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: .5s;
  animation-fill-mode: backwards;
}
.b {
  font-size: 9vw;
  padding-top: 3vh;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}
.c {
  font-size: 8vw;
  padding-top: 5vh;
  animation-name: slide-top;
  animation-duration: 1s;
  animation-delay: 1.5s;
  animation-fill-mode: backwards;
}
</style>